<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard :title="`Riwayat ${title}`">
      <template #action>
        <base-button to="saldo-stockist-center/tambah" type="button">
          <Icon class="h-5 w-5" icon="heroicons:plus-20-solid" />
          {{ title }}
        </base-button>
      </template>

      <Datatable
        :total="getBalance.meta.page.total"
        :perPage="getBalance.meta.page.perPage"
        :currentPage="getBalance.meta.page.currentPage"
        @pagechanged="onPageChangeBalance"
        :is-empty="!getBalance.data?.length"
      >
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Kode Stockist Center
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Tanggal topup
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Jumlah Deposit
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Status
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <div v-if="isLoading" class="flex items-center text-center">
            <loading></loading>
          </div>
          <tbody v-if="getBalance.data?.length">
            <tr
              class="bg-white hover:bg-green-100"
              v-for="(data, dataIdx) in getBalance.data"
              :key="data.id"
              :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{
                  getRelationships(data.attributes.office_uuid).attributes.code
                }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ dayjs(data.attributes.createdAt).format('ll LT') }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{ data.attributes.deposit_amount | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
              >
                <base-badge v-if="data.attributes.is_approved">
                  Valid
                </base-badge>
                <base-badge
                  v-else
                  class="cursor-pointer"
                  @click="handleTopupConfirmation(data)"
                  color="red"
                >
                  Belum Valid
                </base-badge>
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </BaseCard>
    <validation-modal
      @submit="validateTopup(selectedTrxes)"
      @close="() => (openDetail = !openDetail)"
      v-model="openDetail"
    />
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ValidationModal from '@/components/modal/validation-modal.vue';

dayjs.extend(localizedFormat);

export default {
  name: 'BalanceTrxesTable',
  components: {
    Datatable,
    ValidationModal,
  },
  props: {
    title: {
      type: String,
      default: 'Top Up Saldo Deposit SC',
    },
  },

  data() {
    return {
      search: null,
      openDetail: false,
      is_paid: false,
      data: {},
      selectedTrxes: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getBalance: 'balance_trxes/getData',
      isLoading: 'balance_trxes/getLoading',
    }),
    trxesParams() {
      return {
        trxType: 'topup_deposit',
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        // 'fields[offices]': 'code',
        // 'fields[balance-trxes]': 'offices,data,office_uuid,is_approved,createdAt'
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOffices: 'offices/fetchOffices',
      fetchSaldoHistory: 'offices/saldoHistory',
      fetchBalanceTrxes: 'balance_trxes/fetchBalanceTrxes',
      approveTopup: 'balance_trxes/approveTopup',
    }),
    debounceSearch: debounce(function () {
      this.fetchBalanceTrxes({
        ...this.trxesParams,
      });
    }, 300),
    handleTopupConfirmation(trxes) {
      this.selectedTrxes = trxes;
      this.openDetail = !this.openDetail;
    },
    onPageChangeBalance(page) {
      this.fetchBalanceTrxes({
        ...this.trxesParams,
        pageNumber: page,
      });
    },
    async validateTopup(data) {
      await this.approveTopup(data);
      await this.fetchBalanceTrxes({
        ...this.trxesParams,
      });
      this.openDetail = !this.openDetail;
    },
    getRelationships(id) {
      let data = this.getBalance.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    this.fetchBalanceTrxes({
      ...this.trxesParams,
    });
  },
};
</script>
