<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Tambah Saldo Stockist Center
        </h3>
      </div>
      <div class="py-2">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="tanggal"
                class="block text-xs font-bold text-gray-700"
              >
                Tanggal
              </label>
              <input
                v-model="form.date"
                disabled
                type="date"
                name="tanggal"
                id="tanggal"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <div class="field-inset-default relative w-full">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Kode Stockist Center
              </label>
              <button
                type="button"
                @click="openModalStockist"
                class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <input
                v-model="form.code"
                ref="focusNamaStockist"
                @blur="searchStockist"
                type="text"
                name="name"
                id="name"
                class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Kode Stockist"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_stockist"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Stockist Center
              </label>
              <input
                v-model="form.name"
                readonly
                type="text"
                name="nama_stockist"
                id="nama_stockist"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="field-inset-default relative">
              <label
                for="deposit_topup"
                class="block text-xs font-bold text-gray-700"
              >
                Nominal Topup
              </label>
              <div class="inline-flex w-full">
                <div class="flex items-center">
                  <span class="text-gray-500 sm:text-sm">Rp</span>
                </div>
                <input
                  type="text"
                  name="deposit_topup"
                  id="deposit_topup"
                  @input="(e) => onChangeNominalPembayaran(e)"
                  class="ml-2 block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Nominal Topup"
                />
              </div>
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="field-inset-default relative">
              <label
                for="nilai_pengali"
                class="block text-xs font-bold text-gray-700"
              >
                Nilai Pengali
              </label>
              <input
                v-model="setup_value"
                type="number"
                name="nilai_pengali"
                id="nilai_pengali"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nilai_barang"
                class="block text-xs font-bold text-gray-700"
              >
                Nilai Barang
              </label>
              <span
                type="number"
                name="nilai_barang"
                id="nilai_barang"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                >{{ (form.deposit_topup * setup_value) | toCurrency }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <base-button @click="saveForm"> Simpan </base-button>
        <base-button color="white" to="/penjualan/topup/saldo-stockist-center">
          Kembali
        </base-button>
      </div>
    </div>

    <!-- Modal Succes -->

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="successModal"
          link="/penjualan/topup/saldo-stockist-center"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <!-- end of modal success -->

    <!-- modal stockist data -->
    <BaseModal
      :showing="openListStockist"
      @close="openListStockist = !openListStockist"
      :showClose="openListStockist"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Stockist Center
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data stockist center yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="flex justify-end">
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getSimpleOffices.meta.page.total"
              :perPage="getSimpleOffices.meta.page.perPage"
              :currentPage="getSimpleOffices.meta.page.currentPage"
              :meta="getSimpleOffices.meta"
              @pagechanged="onPagechange"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Stockist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Stockist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Alamat
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Telp
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getSimpleOffices.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="changeStockist(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ getOfficeIncluded(data).attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.address }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.phone }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="openListStockist = !openListStockist"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/Modal.vue';
import BaseModal from '@/components/base/BaseModal';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'TambahSaldoStockistCenter',
  components: {
    Datatable,
    Modal,
    BaseModal,
    BaseSearch,
  },
  data() {
    return {
      success: false,
      failed: false,
      openListStockist: false,
      search: null,
      form: {
        date: dayjs().format('YYYY-MM-DD'),
        id: null,
        code: null,
        name: null,
        deposit_topup: null,
      },
      formatLang: 'id-ID',
      formatStyle: 'currency',
      formatCurrency: 'IDR',
      setup_value: null,
      barang_value: null,
    };
  },
  created() {
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
    this.fetchMultiplier().then((response) => {
      this.setup_value = response.data.data[0].attributes.setup_value;
    });
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getMultiplier: 'setups/getMultiplier',
    }),
  },
  methods: {
    ...mapActions({
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      topupStockistCenter: 'stockists_center/topupStockistCenter',
      fetchMultiplier: 'setups/fetchMultiplier',
    }),
    formatter() {
      return new Intl.NumberFormat(this.formatLang, {
        style: this.formatStyle,
        currency: this.formatCurrency,
      });
    },
    openModalStockist() {
      this.openListStockist = !this.openListStockist;

      this.loadStockistCenter();
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    debounceSearch: debounce(function () {
      this.loadStockistCenter();
    }, 300),
    onPagechange(page) {
      this.loadStockistCenter({
        [page === 'next' ? 'page[after]' : 'page[before]']:
          this.getSimpleOffices.meta.page[page === 'next' ? 'to' : 'from'],
      });
    },
    changeStockist(data) {
      this.form.code = this.getOfficeIncluded(data).attributes.code;
      this.searchStockist();
      this.openListStockist = !this.openListStockist;
    },
    searchStockist() {
      if (this.form.code) {
        this.loadStockistCenter({
          'filter[search]': null,
          'filter[code]': this.form.code,
          'page[limit]': 1,
        }).then(() => {
          if (this.getSimpleOffices.data.length > 0) {
            const office = this.getOfficeIncluded(
              this.getSimpleOffices.data[0]
            );

            this.form.id = office.id;
            this.form.code = office.attributes.code;
            this.form.name = office.attributes.name;
          } else {
            this.form.id = null;
            this.form.code = null;
            this.form.name = null;
            this.form.deposit_topup = null;
          }
        });
      }
    },
    saveForm() {
      const payload = {
        id: this.form.id,
        data: {
          deposit_topup: parseInt(this.form.deposit_topup),
          deposit_multiplier: parseFloat(this.setup_value),
        },
      };
      this.topupStockistCenter(payload).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
    clearData() {
      this.form = {
        date: dayjs().format('YYYY-MM-DD'),
        id: null,
        name: null,
        deposit_topup: null,
      };
    },
    onChangeNominalPembayaran(e) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.form.deposit_topup = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    async loadStockistCenter(params = {}) {
      await this.fetchSimpleOffices({
        'page[limit]': 5,
        'filter[office_category_id]': 3,
        'filter[search]': this.search,
        'fields[simple-offices]': 'office',
        'fields[offices]': 'code,name,address,phone',
        include: 'office',
        ...params,
      });
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSimpleOffices,
        simpleOffice.relationships.office.data.id
      );
    },
  },
};
</script>
