<template>
  <section id="laporan-penjualan-pusat" class="my-10 space-y-4 px-8">
    <!-- Filter -->
    <div class="flex items-center justify-between gap-x-2">
      <span class="text-xs text-gray-700">
        <b>{{ filter.originOfficeCategory[0] }}</b> ke
        <b>{{ filter.destinationOfficeCategory[0] }} </b>
        <b>{{ filter.destinationOffice?.attributes.code }}</b> | dari
        <b>{{ dayjs(filter.from_date).format('LL') }}</b> sampai
        <b>{{ dayjs(filter.to_date).format('LL') }}</b> |

        <b>
          {{
            destinationOptions
              .filter((it) => filter.destinations.includes(it.value))
              .map((it) => it.label)
              .join(', ')
          }}
        </b>
        |

        <b>
          {{ filter.category?.[0]?.attributes.name ?? 'Semua Kategori' }}
        </b>
        |

        <b>
          {{
            filter.is_point === null
              ? 'Berpoint + Non Point'
              : filter.is_point
              ? 'Berpoint'
              : 'Non Point'
          }}
        </b>
      </span>
      <div class="flex">
        <AdvancedFilter>
          <template v-slot:filter>
            <div class="flex gap-x-2">
              <!-- origin category -->
              <base-input
                class="w-full"
                label="Kategori Kantor Asal"
                placeholder="Contoh: AB.001"
                with-label
              >
                <base-select-radio
                  :items="officeCategoryOptions"
                  v-model="filter.originOfficeCategory"
                  v-on:change="handleChangeFilter"
                />
              </base-input>
              <!-- destination office -->
              <base-input
                class="w-full"
                label="Kantor Tujuan"
                placeholder="Contoh: AB.0011"
                with-label
              >
                <div
                  class="field-inset-default relative h-10 w-full border shadow-sm"
                >
                  <button
                    @click="openOfficeModal"
                    type="button"
                    class="absolute inset-y-0 right-4 flex cursor-pointer items-center pl-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="h-5 w-5 text-gray-500 hover:text-gray-700"
                    >
                      <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z"></path>
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <input
                    @blur="(e) => onBlurKantor(e.target.value)"
                    v-model="filter.destinationOffice.attributes.code"
                    type="text"
                    name="destination_office"
                    id="destination_office"
                    placeholder="AB.001"
                    class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  />
                </div>
              </base-input>
            </div>
            <div class="text-sm text-gray-700">
              <input
                v-model="filter.is_period"
                type="radio"
                name="is_period"
                id="is_period"
                :value="true"
                class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              <label for="is_period"> Periode </label>
            </div>
            <div class="text-sm text-gray-700">
              <input
                v-model="filter.is_period"
                type="radio"
                name="is_date"
                id="is_date"
                :value="false"
                class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              <label for="is_date"> Tanggal </label>
            </div>
            <template v-if="filter.is_period" v-on:change="handleFilter">
              <base-input label="Tahun" with-label>
                <base-select
                  :options="selectYearOptions"
                  :shadow="false"
                  min-w-fit
                  v-model="filter.year"
                  v-on:change="handleChangeYear"
                />
              </base-input>
              <base-input label="Bulan" with-label>
                <base-select
                  :options="selectMonthOptions"
                  :shadow="false"
                  min-w-fit
                  v-model="filter.period_uuid"
                  v-on:change="handleChangeMonth"
                />
              </base-input>
            </template>
            <div class="flex gap-x-2" v-else>
              <base-input
                class="w-full"
                type="date"
                label="Dari"
                with-label
                v-model="filter.from_date"
                v-on:change="handleChangeFilterDebounce"
              />
              <base-input
                class="w-full"
                type="date"
                label="Sampai"
                with-label
                v-model="filter.to_date"
                v-on:change="handleChangeFilterDebounce"
              />
            </div>
            <base-input
              v-if="filter.destinationOfficeCategory[0] == 'Stockist'"
              label="Kategori Stockist"
              with-label
            >
              <base-select-checkbox
                :items="destinationOptions"
                v-model="filter.destinations"
                v-on:change="handleChangeFilter"
              />
            </base-input>
            <base-input label="Filter Kategori Produk" with-label>
              <base-select-checkbox
                :items="productCategoryOptions"
                v-model="filter.category"
                v-on:change="handleChangeFilter"
                :label="
                  filter.category.length == 0 ? 'Semua Kategori' : undefined
                "
              />
            </base-input>
            <div class="relative w-full rounded-md shadow-sm">
              <label
                for="destinasi_kantor"
                class="mb-2 block text-sm font-medium text-gray-700"
                >Filter Point</label
              ><select
                id="destination"
                v-model="filter.is_point"
                @change="handleChangeFilter"
                class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Berpoint + Non Point</option>
                <option :value="true">Berpoint</option>
                <option :value="false">Non Point</option>
              </select>
            </div>
          </template>
        </AdvancedFilter>
        <base-button :loading="loadingExport" @click="onExport">
          <svg
            v-if="!loadingExport"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            ></path>
          </svg>
        </base-button>
      </div>
    </div>

    <!-- Table -->
    <div class="inline-block min-w-full align-middle">
      <datatable
        :total="getProducts.meta.page.total"
        :perPage="getProducts.meta.page.perPage"
        :currentPage="getProducts.meta.page.currentPage"
        @pagechanged="handleChangePage"
      >
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Kode Barang
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Nama Barang
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                P/N
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Jumlah
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Bonus
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Jumlah Harga
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <div v-if="isLoading" class="flex items-center text-center">
            <loading></loading>
          </div>
          <template v-if="getProducts.data.length">
            <tbody>
              <tr
                class="bg-white hover:bg-green-100"
                v-for="(product, index) in getProducts.data"
                :key="product.id"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ product.attributes.code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ product.attributes.name }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ !!product.attributes.is_point ? 'Point' : 'Non Point' }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{
                    product.attributes.report_sales_price?.total_qty
                      | toCurrency
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{
                    product.attributes.report_sales_price?.total_bonus
                      | toCurrency
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{
                    parseFloat(
                      product.attributes.report_sales_price?.total_price
                    ) | toCurrency
                  }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="bg-white">
                <td
                  colspan="5"
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  Total Penjualan
                </td>
                <td
                  colspan="1"
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ getTotalPrice | toCurrency }}
                </td>
              </tr>
            </tbody>
          </template>
          <tbody v-else>
            <tr class="bg-white">
              <td
                colspan="6"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                Tidak ada data
              </td>
            </tr>
          </tbody>
        </template>
      </datatable>
    </div>
    <OfficeModal
      :isVisible="toggle.officeModal"
      @onSearchKantor="onSearchKantor"
      @onPageChangeKantor="onPageChangeKantor"
      @change-office="changeOffice"
      @closeKantorModal="closeKantorModal"
    />
    <Loading v-if="isLoading" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelectCheckbox from '@/components/base/BaseSelectCheckbox.vue';
import BaseSelectRadio from '@/components/base/BaseSelectRadio.vue';
import AdvancedFilter from '@/components/base/AdvancedFilter.vue';
import Datatable from '@/components/base/Datatable.vue';
import Loading from '@/components/Loading.vue';
import debounce from 'debounce';
import dayjs from 'dayjs';
import { beginningOfMonth, endOfMonth } from '@/services/utils.service';
import OfficeModal from '@/components/office/office-modal.vue';
// import PeriodSelect from '@/components/period/PeriodSelect.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { getListPastNYear } from '@/services/date.service.js';
import { downloadFileUrl } from '@/services/utils.service';

export default {
  name: 'LaporanJumlahPenjualan',
  components: {
    BaseInput,
    Datatable,
    Loading,
    AdvancedFilter,
    BaseSelectCheckbox,
    BaseSelectRadio,
    OfficeModal,
    BaseSelect,
    // PeriodSelect,
  },
  data: function () {
    const begin = beginningOfMonth(new Date());
    const end = endOfMonth(new Date());

    const startDate = dayjs(begin).format('YYYY-MM-DD');
    const endDate = dayjs(end).format('YYYY-MM-DD');

    return {
      loadingExport: false,
      filter: {
        year: null,
        period_uuid: null,
        is_period: true,
        from_date: startDate,
        to_date: endDate,
        destinations: ['prsh', 'kyn', 'scn', 'stockist'],
        category: [],
        destinationOfficeCategory: [null],
        originOfficeCategory: [null],
        is_point: null,
        destinationOffice: {
          id: null,
          attributes: {
            code: 'Semua',
          },
        },
      },
      toggle: {
        officeModal: false,
      },
      pagination: {
        size: 5,
        number: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoadingOrders: 'orders/getLoading',
      isLoadingOffice: 'offices/getLoading',
      isLoadingProduct: 'products/getLoading',
      getProducts: 'products/getProducts',
      getProductCategories: 'product_categories/getProductCategories',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
      getPeriods: 'periods/getPeriods',
      getUser: 'auth/getUser',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
    }),
    isLoading: {
      get() {
        return (
          this.isLoadingOrders || this.isLoadingOffice || this.isLoadingProduct
        );
      },
    },
    getTotalPrice() {
      return this.getProducts.jsonapi.meta.total_penjualan;
    },
    productCategoryOptions: function () {
      if (this.getProductCategories.data.length === 0) return [];
      const productCategories = this.getProductCategories.data.map(
        (productCategory) => {
          return {
            key: productCategory.id,
            label: productCategory.attributes.name,
            value: productCategory,
          };
        }
      );
      return [
        {
          key: 'all',
          label: 'Semua Kategori',
          value: undefined,
        },
        ...productCategories,
      ];
    },
    officeCategoryOptions: function () {
      return [
        {
          key: 'semua',
          label: 'Semua',
          value: undefined,
        },
        {
          key: 'pusat',
          label: 'Pusat',
          value: 'Pusat',
        },
        {
          key: 'sc',
          label: 'Stockist Center',
          value: 'Stockist Center',
        },
        {
          key: 'pelayanan',
          label: 'Kantor Pelayanan',
          value: 'Kantor Pelayanan',
        },
      ];
    },
    destinationOptions: function () {
      return [
        {
          key: 'semua',
          label: 'Semua',
          value: undefined,
        },
        {
          key: 'stockist',
          label: 'Stockist',
          value: 'stockist',
        },
        {
          key: 'scn',
          label: 'SCN',
          value: 'scn',
        },
        {
          key: 'prsh',
          label: 'PRSH',
          value: 'prsh',
        },
        {
          key: 'kyn',
          label: 'KYN',
          value: 'kyn',
        },
      ];
    },
    orders: function () {
      return this.getOrders;
    },
    fetchProductsParams: function () {
      const originOfficeCategory = this.getAvailableOfficeCategories.find(
        (item) => item.attributes.name === this.filter.originOfficeCategory[0]
      );
      const destinationOfficeCategory = this.getAvailableOfficeCategories.find(
        (item) =>
          item.attributes.name === this.filter.destinationOfficeCategory[0]
      );
      const destinationOffice = this.filter.destinationOffice;

      const params = {
        pageSize: this.pagination.size,
        pageNumber: this.pagination.number,
        reportMode: 'penjualan',
        include: '',
        'filter[period_uuid]': this.filter.period_uuid,
        productCategoryId:
          this.filter.category.map((it) => it?.id)?.join(',') ?? undefined,
        'filter[show-orders-meta]': true,
        'filter[origin_office_category_id]': originOfficeCategory?.id,
        'filter[destination_office_id]':
          destinationOffice?.attributes.office_id,
        'filter[destination_office_category_id]': destinationOfficeCategory?.id,
        'filter[fromDate]': this.filter.from_date,
        'filter[toDate]': this.filter.to_date,
        'filter[stockist_type]':
          this.filter.destinationOfficeCategory[0] == 'Stockist'
            ? this.filter.destinations.join(',')
            : undefined,
        'filter[is_point]':
          typeof this.filter.is_point === 'boolean'
            ? this.filter.is_point
              ? 1
              : 0
            : undefined,
        // fields: {
        //   orders:
        //     'origin_code,destination_code,createdAt,final_price,origin_warehouse_code,notes,report_sales_price', // comment dlu krn report sales blm bisa di sparse
        // },
      };

      if (this.filter.is_period) {
        delete params['filter[fromDate]'];
        delete params['filter[toDate]'];
      } else {
        delete params['filter[period_uuid]'];
      }

      return params;
    },
    fetchOfficeParams() {
      return {
        'filter[office_category_id]': 4,
        'fields[simple-offices]': 'code,office',
        'fields[offices]': 'code,name,address,phone',
        'include': 'office',
        'pagination[type]': 'cursor',
        'page[limit]': 5
      };
    },
    selectYearOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListPastNYear().map((year) => ({
          key: year,
          value: year,
          label: year,
        })),
      ];
    },
    selectMonthOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...this.getPeriods.data.map((period) => ({
          key: period.attributes.name,
          value: period.id,
          label: period.attributes.name,
        })),
      ];
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchProductCategories: 'product_categories/fetchProductCategories',
      fetchProducts: 'products/fetchProducts',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      fetchPeriods: 'periods/fetchPeriods',
      exportProductSaleReport: 'products/exportProductSaleReport',
      createAlert: 'alert/createAlert',
    }),
    handleChangeYear() {
      this.fetchPeriods({
        'filter[year]': this.filter.year,
      });
    },
    handleChangeMonth() {
      this.fetchProducts(this.fetchProductsParams);
    },
    handleFilter() {},
    reload: function () {
      this.fetchProducts(this.fetchProductsParams);
    },
    handleChangePage: function (page) {
      this.pagination.number = page;

      this.reload();
    },
    handleChangeFilter: function () {
      this.reload();
    },
    handleChangeFilterDebounce: debounce(function () {
      this.reload();
    }, 500),
    async openOfficeModal() {
      await this.fetchSimpleOffices(this.fetchOfficeParams);
      this.toggle.officeModal = true;
    },
    changeOffice(office) {
      this.filter.destinationOffice = this.getSingleIncludedType(
        'office',
        this.getSimpleOffices,
        office
      );
      this.toggle.officeModal = false;
      this.fetchProducts(this.fetchProductsParams);
    },
    onPageChangeKantor(page) {
      this.fetchSimpleOffices({
        ...this.fetchOfficeParams,
        ...page,
      });
    },
    onBlurKantor(search) {
      this.fetchSimpleOffices({
        ...this.fetchOfficeParams,
        search,
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.filter.destinationOffice = res.data.data[0];
          this.fetchProducts(this.fetchProductsParams);
        }
      });
    },
    onSearchKantor(search) {
      this.fetchSimpleOffices({
        ...this.fetchOfficeParams,
        'filter[search]': search,
      });
    },
    closeKantorModal() {
      this.toggle.officeModal = false;
    },
    async onExport() {
      this.loadingExport = true;

      await this.exportProductSaleReport({
        params: this.fetchProductsParams,
      });
    },
    listenToExportProductEvent() {
      const exportProductChannel = this.$pusher.subscribe(
        `private-ProductSalesExport.${this.getUser.id}`
      );

      exportProductChannel.bind('ProductSalesExportStatusUpdated', (e) => {
        if (e.status === 'processing') {
          this.loadingExport = true;
        } else if (e.status === 'failed') {
          this.loadingExport = false;

          this.createAlert({ data: e.message, status: 'error' });
        } else if (e.status === 'finished') {
          this.loadingExport = false;

          downloadFileUrl(e.file_url);
        }
      });
    },
    stopListenToExportProductEvent() {
      this.$pusher.unsubscribe(`private-ProductSalesExport.${this.getUser.id}`);
    },
  },
  created: function () {
    this.fetchProductCategories();
    this.fetchPeriods({
      'filter[year]': dayjs().get('year'),
    }).then((response) => {
      this.filter.period_uuid = response.data.data[new Date().getMonth()].id;
      this.fetchProducts(this.fetchProductsParams);
    });
    this.listenToExportProductEvent();
  },
  beforeRouteLeave(to, from, next) {
    this.stopListenToExportProductEvent();

    next();
  },
};
</script>
