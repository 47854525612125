<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <BaseCard
        title="Riwayat Restock"
        description="Riwayat Restock Oleh Stockist Center"
      >
        <div class="flex flex-col">
          <div class="mt-2 flex items-end justify-end space-x-2">
            <base-input label="Status Faktur">
              <select
                v-model="is_valid_for_payment"
                @change="filterByCompleted"
                class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Valid</option>
                <option :value="false">Draft</option>
              </select>
            </base-input>
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <Datatable
            :total="getOrders.meta.page.total"
            :perPage="getOrders.meta.page.perPage"
            :currentPage="getOrders.meta.page.currentPage"
            @pagechanged="onPageChangeOrder"
            :meta="getOrders.meta"
            cursor
            :is-empty="!getOrders.data.length"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    @click="handleSort('origin_code', sort.origin_code)"
                    scope="col"
                    class="flex cursor-pointer gap-x-4 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nomor Restock
                    <svg
                      v-if="sort.origin_code"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode SC/Kode Gudang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Status Faktur
                  </th>
                  <th
                    @click="handleSort('order_status', sort.order_status)"
                    scope="col"
                    class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    <div class="flex cursor-pointer justify-center gap-x-4">
                      Status Order
                      <svg
                        v-if="sort.order_status"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-4 w-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-4 w-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </div>
                  </th>
                  <th
                    @click="
                      handleSort('warehouse_status', sort.warehouse_status)
                    "
                    scope="col"
                    class="flex cursor-pointer justify-center gap-x-4 px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Status Proses
                    <svg
                      v-if="sort.warehouse_status"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <div v-if="isLoading" class="flex items-center text-center">
                <loading></loading>
              </div>
              <tbody v-if="getOrders.data.length">
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getOrders.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click.prevent="changeSale(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    <div class="text-sm font-bold text-gray-900">
                      {{ data.attributes.origin_code }} /
                      {{ data.attributes.destination_code }}
                    </div>
                    <div class="text-xs font-light text-gray-500">
                      {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getRelationships(
                        data.relationships['destination-office'].data.id
                      )?.attributes.code
                    }}
                    /
                    {{
                      getRelationships(
                        data.relationships['origin-office'].data.id
                      )?.attributes.code
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                  >
                    <span
                      v-if="data.attributes.is_valid_for_payment"
                      class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                    >
                      Valid
                    </span>
                    <span
                      v-else-if="
                        !data.attributes.is_valid_for_payment &&
                        !data.attributes.canceled_by_user_id &&
                        !data.attributes.canceled_from
                      "
                      class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                    >
                      Draft
                    </span>
                    <span
                      v-else
                      class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-700"
                    >
                      Revisi
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                  >
                    <span
                      class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium"
                      :class="getClass(data.attributes.order_status)"
                    >
                      {{ data.attributes.order_status }}
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                  >
                    <span
                      class="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium"
                      :class="getClass(data.attributes.warehouse_status)"
                    >
                      {{ data.attributes.warehouse_status }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </BaseCard>

      <OrderDetailModal
        title="Detail Restock"
        :visible="openDetail"
        :order="getOrder.data"
        :editable="false"
        :deleteable="false"
        :validateable="false"
        :with-payments="false"
        validation-ignore-payment-status
        @close="openDetail = !openDetail"
        @refresh="handleValidated"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import dayjs from 'dayjs';
import BaseCard from '@/components/base/BaseCard.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    OrderDetailModal,
    BaseCard,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      is_valid_for_payment: null,
      openDetail: false,
      dataPenjualan: {},
      sort: {
        origin_code: false,
        warehouse_status: false,
        order_status: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
    }),
    fetchOrdersParams() {
      return {
        include: 'destination-office,origin-office',
        'page[limit]': 5,
        'filter[search]': this.search,
        'filter[destination_office_category_id]': 3,
        fields: {
          orders:
            'destination-office,canceled_by_user_id,canceled_from,warehouse_status,origin-office,createdAt,updatedAt,deletedAt,origin_code,destination_code,final_price,is_valid_for_payment,destination_office_code,origin_warehouse_code,order_status,has_bundle,is_minus_bv',
        },
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      updateOrder: 'orders/updateOrder',
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
    }),
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getClass(attribut) {
      return {
        'bg-yellow-100 text-yellow-800':
          attribut === 'Diproses Gudang' || attribut === 'Diproses Kasir',
        'bg-blue-100 text-blue-800':
          attribut === 'Dikirim' || attribut === 'Persiapan',
        'bg-red-100 text-red-800':
          attribut === 'Ada Susulan' ||
          attribut === 'Belum Masuk Gudang' ||
          attribut === 'Draft',
        'bg-green-100 text-green-800': attribut === 'Selesai',
      };
    },
    debounceSearch: debounce(function () {
      this.fetchOrders(this.fetchOrdersParams);
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({ ...this.fetchOrdersParams, ...page });
    },
    filterByCompleted() {
      this.fetchOrders(this.fetchOrdersParams);
    },
    changeSale(data) {
      this.openDetail = !this.openDetail;
      this.fetchOrder({ id: data.attributes.order_id }).then(() => {
        this.dataPenjualan = this.getOrder.data;
      });
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            // order_type: 'sale',
            pageNumber: 1,
            'page[limit]': 5,
            destination_office_category: 3,
            ...this.fetchOrdersParams,
          });
        }
      });
    },
    handleValidated() {
      this.openDetail = false;
      this.fetchOrders(this.fetchOrdersParams);
    },
    handleSort(column, currentState) {
      this.sort[column] = !this.sort[column];

      this.fetchOrders({
        sort: !currentState ? column : `-${column}`,
        ...this.fetchOrdersParams,
      });
    },
  },
  created() {
    this.fetchOrders({
      ...this.fetchOrdersParams,
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
